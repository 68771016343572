import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsBundle, useSlugName, } from '../../hooks/readFromUrls';

import ViewCourse from './components/ViewCourse'
import { CoverMedia, Page } from '../../ui';
import { selectStoreName, selectUserTheme, selectCanIEdit, selectStoreOwner } from './globalSlice';
import { fetchCourse, selectCourseCoverMedia, selectCourseIsPublic, selectCtaBanner, selectLayoutId, selectStatus } from './courseSlice';
import ViewCoursePackage from './components/ViewCoursePackage';
import { selectIsIncarnated, selectIsLoggedIn } from '../auth/authSlice';
import { STATUS_LOADING } from '../../utils/constants';
import Loading from '../../ui/Loading';
import SEO from "../../components/SEO/SEO";
import { useTranslation } from 'react-i18next';
import GATracker from "../../helpers/GATracker";
import CTABanner from './components/CTABanner';

const WebshopCourse = ({ isPackage }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [courseError, setCourseError] = useState(null);

  const coverMedia = useSelector(selectCourseCoverMedia);
  const ctaBanner = useSelector(selectCtaBanner)
  const loadCoursesStatus = useSelector(state => state.courses.status);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userTheme = useSelector(selectUserTheme);
  const loadStatus = useSelector(selectStatus);
  const isIncarnated = useSelector(selectIsIncarnated)
  const storeOwner = useSelector(selectStoreOwner)
  const courseId = useSelector(selectLayoutId)

  const slugName = useSlugName()
  const isBundle = useIsBundle()

  const storeName = useSelector(selectStoreName)
  const isPublic = useSelector(selectCourseIsPublic)

  const isCourseEditor = useSelector(selectCanIEdit)

  useEffect(() => {
    if (!isLoggedIn && i18n.language !== storeOwner.locale) {
      i18n.changeLanguage(storeOwner.locale)
    }
  }, [storeOwner, i18n, isLoggedIn])

  useEffect(() => {
    storeName && slugName && dispatch(fetchCourse({ storeName, slugName, type: isBundle ? 2 : 1 }))
      .then((action) => {
        action.payload.errorCode &&
          setCourseError(action.payload.errorCode === 403 ?
            t('webshop.course.courseNotAvailable')
            : action.payload.response.errMsg)
      })

  }, [slugName, storeName, dispatch, isBundle, isLoggedIn, isIncarnated]);

  if (loadCoursesStatus !== 'succeeded') return null

  return (
    loadStatus === STATUS_LOADING ? <Loading /> :
      (!isPublic && !isCourseEditor) || courseError ? <Page>{courseError ? courseError : t('webshop.course.courseNotAvailable')}</Page> :
        <>
          <div className="course-main-section-container">
            {<>
              <CoverMedia
                videoPreview={coverMedia?.video?.url}
                themeColor={userTheme.color}
                thumbnailImage={coverMedia?.image?.url}
                courseId={courseId}
              />
              <CTABanner 
                themeColor={userTheme.color} 
                courseId={courseId} 
                buyButton={true} 
                info={ctaBanner?.hasOwnProperty('title') ? ctaBanner : {title:'Begin your journey today!', description:'Unlock new skills and knowledge with a expert-led course. Enroll now!'}}
              />
            </>}
            <div className="mid-section-container">
              {isPackage ? <ViewCoursePackage /> : <ViewCourse />}
            </div>
          </div>
          <SEO title={storeName} image={coverMedia?.image?.url} name={storeName} type={'summary_large_image'} />
          <GATracker />
        </>
  );
};

export default WebshopCourse;
