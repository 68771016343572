import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import { Textinput, Typo, Button, Flex, Page, Figure } from '../../../ui';
import ImageUploader from '../../media/uploaders/ImageUploader';
import BuildInfoIcons from '../../../components/InfoIconSection/BuildInfoIcons';
import { fetchCourse, saveCourse, selectCtaBanner, selectLayout } from '../courseSlice';
import { CONTENT_SMALL } from '../../../utils/media';
// import BuildTestimonies from '../../../components/Testimonies/BuildTestimonies';
import { selectMyStore } from '../../auth/authSlice';
import CoverMediaLayout from './CoverMediaLayout';

const EditStoreCourse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const myStore = useSelector(selectMyStore)
  const layoutStructure = useSelector(selectLayout)
  const ctaBanner = useSelector(selectCtaBanner)

  const [infoIcons, setInfoIcons] = useState([]);
  const [infoPoints, setInfoPoints] = useState([]);
  const [teacherInfo, setTeacherInfo] = useState({});
  const [highlightedInfo, setHighlightedInfo] = useState({});
  const [ctaBannerInfo, setCtaBannerInfo] = useState();
  const [promotionVideo, setPromotionVideo] = useState({ id: null, url: null });
  const [coverImage, setCoverImage] = useState();
  const [needToBeSaved, setNeedToBeSaved] = useState(false);

  // const [testimonies, setTestimonies] = useState([]);

  const { isPackage, slug } = useOutletContext();


  useEffect(() => {
    if (layoutStructure) {
      const tempLayout = cloneDeep(layoutStructure)
      setInfoIcons(tempLayout.infoIcons);
      setInfoPoints(tempLayout.infoPoints);
      setTeacherInfo(tempLayout.teacher);
      setHighlightedInfo(tempLayout.highlightedInfo);
      setPromotionVideo(tempLayout.coverMedia.video)
      setCoverImage(tempLayout.coverMedia.image)
      setCtaBannerInfo(cloneDeep(ctaBanner));
      // setTestimonies(tempLayout.testimonies)
    }
  }, [layoutStructure, ctaBanner]);

  useEffect(() => {
    let needSave = false
    if (JSON.stringify(teacherInfo) !== JSON.stringify(layoutStructure.teacher) ||
      JSON.stringify(infoIcons) !== JSON.stringify(layoutStructure.infoIcons) ||
      JSON.stringify(highlightedInfo) !== JSON.stringify(layoutStructure.highlightedInfo) ||
      JSON.stringify(infoPoints) !== JSON.stringify(layoutStructure.infoPoints) ||
      JSON.stringify(coverImage) !== JSON.stringify(layoutStructure.coverMedia?.image) ||
      JSON.stringify(ctaBanner) !== JSON.stringify(ctaBannerInfo) ||
      JSON.stringify(promotionVideo) !== JSON.stringify(layoutStructure.coverMedia.video)
    ) needSave = true
    setNeedToBeSaved(needSave)
  }, [layoutStructure, infoIcons, ctaBanner, ctaBannerInfo, highlightedInfo, teacherInfo, infoPoints, coverImage, promotionVideo])


  const editTeacherInfo = useCallback(e => {
    setTeacherInfo({ ...teacherInfo, [e.target.dataset.propname]: e.target.value });
  }, [teacherInfo]);

  const editHighlightedInfo = useCallback(e => {
    setHighlightedInfo({ ...highlightedInfo, [e.target.dataset.propname]: e.target.value });
  }, [highlightedInfo]);

  const removeMockedIds = useCallback((data) => {
    return data.map(el => {
      if (el.hasOwnProperty('id')) delete el.id;
      return el;
    })
  }, [])

  const addImageToTeacher = useCallback(image => {
    setTeacherInfo({ ...teacherInfo, avatarUrl: image?.url, avatarId: image?.id })
  }, [teacherInfo])

  const addImageToHighlightedInfo = useCallback(image => {
    setHighlightedInfo({ ...highlightedInfo, image: image?.url, })
  }, [highlightedInfo])

  const editCtaBannerInfo = useCallback(e => {
    setCtaBannerInfo({ ...ctaBannerInfo, [e.target.dataset.propname]: e.target.value });
  }, [ctaBannerInfo]);

  const saveStructure = useCallback(() => {
    const newLayoutData = {
      id: layoutStructure.id,
      // name: layoutStructure.name, //we do not want to change this here
      videoUrl: promotionVideo?.url,
      settings: {
        ...layoutStructure.dontDeleteSettings,
        layout: {
          courseCoverMedia: {
            image: coverImage,
            video: promotionVideo
          },
          infoPoints: removeMockedIds(cloneDeep(infoPoints)),
          infoIcons: removeMockedIds(cloneDeep(infoIcons)),
          teacher: teacherInfo,
          highlightedInfo: highlightedInfo,
          ctaBanner: ctaBannerInfo,

          // testimonies: testimonies,

          //Should we create a way to edit TOC to make it more interesting info?
          // state.courseContent = courseContent || state.courseToc;
        }
      }
    };
    dispatch(saveCourse({ newData: newLayoutData, isPackage: isPackage })).then(() =>
      myStore && slug && dispatch(fetchCourse({ storeName: myStore, slugName: slug, type: isPackage ? 2 : 1 }))
    )
  }, [dispatch, coverImage, infoPoints, ctaBannerInfo, isPackage, highlightedInfo, teacherInfo, layoutStructure, removeMockedIds, infoIcons, promotionVideo, myStore, slug]);

  const saveVideo = useCallback((video) => {
    setPromotionVideo(video)
  }, [])

  const saveImageCoverToLayout = useCallback((image) => {
    if (image?.id === -1)
      setCoverImage(null)
    else setCoverImage(image)
  }, [])

  return (
    <Page size={CONTENT_SMALL} align={'center'} gap='50'
      staticElements={
        <Flex justify='flex-end' width='100%' gap='30'>
          <Button icon='Save' onClick={saveStructure} disabled={!needToBeSaved} >{t('globals.save')}</Button>
          <Button icon='Visible' disabled={needToBeSaved} onClick={() => navigate(`/store/${myStore}${isPackage ? '/package' : ''}/${slug}`)}>{t('webshop.seeCourse')}</Button>
        </Flex>
      }
    >
      <CoverMediaLayout
        coverImage={coverImage}
        promotionVideo={promotionVideo}
        saveVideo={saveVideo}
        saveImage={saveImageCoverToLayout}
        setPromotionVideo={setPromotionVideo}
      />
      <Flex direction='column' width='100%' align='flex-start'>
        <Typo.ContentSmallHeader $marginBottom='10'>{t('webshop.build.ctaBannerTitle')}</Typo.ContentSmallHeader>
        <Typo.Text>{t('webshop.build.ctaBannerDescription')}</Typo.Text>

        <Textinput
          label={t('globals.title')}
          propName={'title'}
          defaultValue={ctaBanner?.title}
          onBlurValue={editCtaBannerInfo}
        />
        <Textinput
          label={t('globals.description')}
          propName={'description'}
          defaultValue={ctaBanner?.description}
          onBlurValue={editCtaBannerInfo}
          isMultiline
        />
      </Flex>

      {/* highlightedInfo:{title:'', text:'', image: {} '}, */}
      <Flex direction='column' width='100%' align='flex-start' gap='10'>
        <Flex direction='column' gap='10' align='flex-start'>
          <Typo.ContentSmallHeader>{t('webshop.build.highlightedInfoTitle')}</Typo.ContentSmallHeader>
          <Typo.Text>{t('webshop.build.highlightedInfoDescription')}</Typo.Text>
        </Flex>
        <Flex width='100%' gap='20'>
          <Flex auto direction='column'>
            <Textinput
              key={`title-${highlightedInfo?.title}`}
              label={t('globals.headline')}
              propName={'title'}
              defaultValue={highlightedInfo?.title}
              onBlurValue={editHighlightedInfo}
            />
            <Textinput
              label={t('globals.description')}
              propName={'text'}
              defaultValue={highlightedInfo?.text}
              onBlurValue={editHighlightedInfo}
              isMultiline
            />
          </Flex>
          <ImageUploader
            saveByDelete={true}
            type={'highlighted'}
            id={'highlightedInfo'}
            saveAction={addImageToHighlightedInfo}
            imageToPreview={highlightedInfo?.image}
          >
            {highlightedInfo?.image && <Figure.Rectangle ><img alt={'imageToPreview'} src={highlightedInfo?.image} loading='lazy' /></Figure.Rectangle>}
          </ImageUploader>
        </Flex>
      </Flex>

      <BuildInfoIcons infoIcons={infoIcons} setInfoIcons={setInfoIcons} isMini={true} />

      <BuildInfoIcons setInfoIcons={setInfoPoints} infoIcons={infoPoints} />

      {/* teacher:{name:'', about:'', image: {} '} */}
      <Flex direction='column' width='100%' align='flex-start'>
        <Typo.ContentSmallHeader>{t('webshop.build.teacherTitle')}</Typo.ContentSmallHeader>
        <Flex width='100%' gap='20'>
          <ImageUploader
            saveByDelete={true}
            type={'teacher'}
            id={'teacher'}
            saveAction={addImageToTeacher}
            imageToPreview={teacherInfo?.avatarUrl}
          >
            {teacherInfo?.avatarUrl && <Figure.Rectangle ><img alt={'imageToPreview'} src={teacherInfo?.avatarUrl} loading='lazy' /></Figure.Rectangle>}
          </ImageUploader>
          <Flex auto direction='column'>
            <Textinput
              key={`name-${teacherInfo?.name}`}
              label={t('globals.name')}
              propName={'name'}
              defaultValue={teacherInfo?.name}
              onBlurValue={editTeacherInfo}
            />
            <Textinput
              label={t('globals.about')}
              propName={'about'}
              defaultValue={teacherInfo?.about}
              onBlurValue={editTeacherInfo}
              isMultiline
            />
          </Flex>
        </Flex>
      </Flex>

      {/*<BuildTestimonies setTestimonies={setTestimonies} testimonies={testimonies} />*/}


      {/* NOT FOR NOW */}
      {/* Emails gatherer ??? */}
    </Page >
  );
};

export default EditStoreCourse;
