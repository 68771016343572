import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CoverMedia, Loading } from '../../ui';
import ViewHome from './components/ViewHome'
import BuildHome from './components/BuildHome'
// import Menu from './components/Menu';
import WrongStore from './components/WrongStore';
import NoStoreToShow from './components/NoStoreToShow';
import { fetchStore, selectStoreOwner, selectUserTheme } from './globalSlice';
import { selectCoverMedia, selectCtaBanner, selectHasLayout, selectStatus } from './homeSlice';
import { STATUS_FAILED, STATUS_LOADING } from '../../utils/constants';
import { selectWebshopCourses } from './coursesSlice';
import { useWebshopName } from '../../hooks/readFromUrls';
import SEO from '../../components/SEO/SEO';
import GATracker from '../../helpers/GATracker';
import { selectIsLoggedIn } from '../auth/authSlice';
import { useTranslation } from 'react-i18next';
import CTABanner from './components/CTABanner';
import { useNavigate } from 'react-router';

const Webshop = ({ edit }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const readerMode = !edit
  const hasLayout = useSelector(selectHasLayout)
  const loadStatus = useSelector(selectStatus)
  const items = useSelector(selectWebshopCourses)
  const coverMedia = useSelector(selectCoverMedia)
  const ctaBanner = useSelector(selectCtaBanner)
  const storeOwner = useSelector(selectStoreOwner)
  const userTheme = useSelector(selectUserTheme);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const webshop = useWebshopName();

  useEffect(() => {
    dispatch(fetchStore({ store: webshop }));
  }, [dispatch, webshop])

  useEffect(() => {
    if (!isLoggedIn && i18n.language !== storeOwner.locale) {
      i18n.changeLanguage(storeOwner.locale)
    }
  }, [storeOwner, i18n, isLoggedIn])

  const goToCourses = useCallback(() => {
    navigate(`/store/${webshop}/#courses`);
  }, [navigate, webshop])

  return ((loadStatus === 'idle' || loadStatus === STATUS_LOADING) ? <Loading /> :
    <>
      {loadStatus === STATUS_FAILED ?
        <WrongStore errorMessage={''} />
        :
        !readerMode ?
          <BuildHome />
          :
          (!hasLayout && !items.length) ?
            <NoStoreToShow ownerName={storeOwner.name} />
            :
            <div className="home-container">
              {readerMode && <>
                <CoverMedia
                  videoPreview={coverMedia?.video?.url}
                  thumbnailImage={coverMedia?.image?.url}
                  themeColor={userTheme.color}
                  />
                <CTABanner 
                  themeColor={userTheme.color} 
                  goToAllCourses={goToCourses} 
                  info={ctaBanner?.hasOwnProperty('title') ? ctaBanner : {title:'The best way to learn!', description:'Explore our courses led by industry experts. Whether you want to enhance your career, develop new skills, or pursue a passion, we have the right course for you. Start your learning journey today!'}} 
                />
              </>
              }
              {/* {readerMode && hasLayout && <Menu navBar />} */}
              <div className="mid-section-container">
                <ViewHome />
              </div>
            </ div>
      }
      <SEO title={storeOwner.name} image={coverMedia?.image?.url} name={storeOwner.name} type={'summary_large_image'} />
      <GATracker />
    </>
  );
};

export default Webshop
