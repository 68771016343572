import React from 'react';
import styled from 'styled-components';
import { CONTENT_MINI } from '../../utils/media';

const Wrapper = styled.div` 
  position: relative;
  padding: 0 0 0 24px;
  max-width: ${CONTENT_MINI}px;
  p::before {
    content: " ";
    background: ${({ theme, $color }) => !$color ? theme.colors.PRIMARY : $color};
    padding: 0 4px;
    position: absolute;
    left: 4px;
    top: 10px;
    height: 8px;
    display: grid;
    place-items: center;
    border-radius: 6px;
  }
`

const ListMarker = ({ children, $color }) => {

  return (
    <Wrapper $color={$color}>
      {children}
    </Wrapper >
  );
}

export default ListMarker;
