import React from 'react';
import { IconButton, Badge, Tooltip } from '@mui/material';
import { Icon } from '..';
import styled from 'styled-components'
import theme from '../theme';
import { useTranslation } from 'react-i18next';

const sizes = { 'small': '32px', 'standard': '41px', 'xxl': '70px' }

const IconButtonStyled = styled(IconButton)`
  max-height: ${({ size }) => sizes[size] || sizes.standard};
  width: ${({ size }) => sizes[size] || sizes.standard};
  height: ${({ size }) => sizes[size] || sizes.standard};
`
const BadgeStyled = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${({ badgecolor }) => badgecolor};
    color: ${(props) => props.theme.colors.NEUTRAL_0};
  }
`;

const IconAsButton = ({ hasBadge, badgeInfo, iconName, color, clickHandle, className, disabled, size, tooltipLabel, placement }) => {
  const { t } = useTranslation();

  const badgeIcon = (Component, props) => {
    const { badgeInfo, hasBadge, color } = props
    return hasBadge ?
      <BadgeStyled badgeContent={badgeInfo} badgecolor={theme.colors.ACTION} >
        <Component color={color} />
      </BadgeStyled>
      : <Component color={color} size={size} />
  }

  return (
    <Tooltip title={tooltipLabel || t(`icons.${iconName}`)} placement={placement || 'bottom'}>
      <IconButtonStyled aria-label={iconName} onClick={clickHandle} className={className} disabled={disabled} size={size}>
        {badgeIcon(Icon[iconName], { badgeInfo, iconName, hasBadge, color, size })}
      </IconButtonStyled>
    </Tooltip >
  );
};

IconAsButton.displayName = 'IconAsButton'

export default IconAsButton;
