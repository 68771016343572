import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  List,
  Box,
  Drawer,
  Divider,
  ListItemIcon,
  ListSubheader,
  ListItemButton
} from '@mui/material';
import {
  setMenuSliderToggle,
  selectMenuSliderToggle,
  setIncarnateModal,
} from '../../features/global/globalSlice';
import {
  selectPaymentType,
  setPaymentType,
} from '../../features/webshop/globalSlice';
import { selectAuthState, selectMyStore, selectIsIncarnated, wakeUp, selectIsAdmin } from '../../features/auth/authSlice';
import {  Flex, Icon, theme, Typo } from '../../ui';
import { selectUnreadNotifications } from '../../features/notifications/notificationsSlice';
import { useModule } from '../../hooks/readFromUrls';
import { MODULES_LIST } from '../../utils/constants';

const MenuHeader = styled.div`
  display:flex;
  height:${(props) => props.theme.headerSize};
  // padding:1rem;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: ${(props) => props.theme.colors.NEUTRAL_0};
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  gap:10px;
`
const UserDescription = styled.div`
  width: 210px;
  overflow-wrap: break-word;
  margin-left:56px;
`
const ItemDescription = styled(Typo.MediumText)`
  font-weight:400;
`
const ListItemButtonStyled = styled(ListItemButton)`
  &.Mui-selected{
    background-color:${theme.colors.COURSIO_LIGHT} !important;
  }
`
const FlexStyled = styled(Flex)`
  margin:1rem 0 0.5rem;
`

const MenuSlider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const myStore = useSelector(selectMyStore);
  const authState = useSelector(selectAuthState);
  const paymentType = useSelector(selectPaymentType);
  const isIncarnated = useSelector(selectIsIncarnated);
  const unreadNotifications = useSelector(selectUnreadNotifications);
  const menuSliderToggle = useSelector(selectMenuSliderToggle);
  const { user, roles } = authState?.userInfo || {};
  const module = useModule();
  const isAdmin = useSelector(selectIsAdmin);
  const isBasicUser = roles && roles[0] === 'reader';

  useEffect(() => {
    setSelectedIndex(MODULES_LIST[module]?.index);
  }, [module])

  const handleTestPayment = useCallback(() => {
    dispatch(setPaymentType(paymentType === 9 ? 6 : 9))
  }, [dispatch, paymentType]);

  const handleLogOut = useCallback(() => {
    navigate('/logout')
    // dispatch(logOut());
  }, [navigate])

  const openIncarnationModal = useCallback(() => {
    dispatch(setIncarnateModal(true))
  }, [dispatch]);

  const wakeUpFromIncarnation = useCallback(() => {
    dispatch(wakeUp())
  }, [dispatch])

  const handleSupportLink = useCallback(() => {
    // const link = lang === 'sv' ? 'https://support.coursio.com/hc/sv' : 'https://support.coursio.com/hc/en-us';
    let supportLink = document.createElement('a')
    supportLink.href = 'https://support.coursio.com/hc/sv'
    supportLink.target = '_blank';
    supportLink.rel = 'noopener noreferrer';
    supportLink.click();
  }, [dispatch])


  const handleListItemClick = useCallback((index, to) => {
    navigate(to)
  }, [navigate])

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(setMenuSliderToggle(open))
  };

  const Profile = useMemo(() => {
    return (
      <MenuHeader>
        {/* <Avatar url={user?.avatarUrl} name={user?.displayName} size='small' /> */}
        <UserDescription>
          <ItemDescription>{user?.displayName}</ItemDescription>
          {roles && <Typo.Info>{t(`roles.${roles[0]}`)}</Typo.Info>}
        </UserDescription>
      </MenuHeader>
    )
  }, [roles, t, user])

  const ListItem = useCallback((item) => {
    const ModuleIcon = Icon[item.icon]
    return (<ListItemButtonStyled key={item.index}
      selected={selectedIndex === item.index}
      onClick={() => {
        const url = typeof item.url === 'string' ? item.url : item.url(myStore)
        return handleListItemClick(item.index, url)
      }}
    >
      <ListItemIcon><ModuleIcon color={theme.colors.PRIMARY} /></ListItemIcon>
      <ItemDescription>{item.label(t, unreadNotifications)}</ItemDescription>
    </ListItemButtonStyled >)

  }, [selectedIndex, t, handleListItemClick, unreadNotifications, myStore]);

  const ifSameRoles = (roles, givenRoles) => roles?.filter(el => givenRoles.indexOf(el) >= 0).length > 0;

  const renderPanelItems = (roles) => {
    let panelItems = [];
    for (let key in MODULES_LIST) {
      if (ifSameRoles(roles, MODULES_LIST[key].accessRights)) {
        panelItems = [...panelItems, {
          section: MODULES_LIST[key].section,
          withoutSectionName: MODULES_LIST[key].withoutSectionName
        }]
      }
    }
    return [...new Map(panelItems.map(v => [v.section, v])).values()]
  }

  const subModuleRender = sectionName => {
    return Object.keys(MODULES_LIST).map((key) => {
      const item = MODULES_LIST[key]
      if (ifSameRoles(roles, MODULES_LIST[key].accessRights))
        return item.section === sectionName && ListItem(item)
    })
  }

  const subSectionRender = (action, icon, description) => {
    const PassedIcon = Icon[icon];
    return (
      <ListItemButtonStyled onClick={action}>
        <ListItemIcon>
          <PassedIcon color={theme.colors.PRIMARY} />
        </ListItemIcon>
        <ItemDescription>{description}</ItemDescription>
      </ListItemButtonStyled>
    )
  }

  const sectionRender = (sectionName, withoutSectionName) => {
    return (
      withoutSectionName ?
        <List key={sectionName}>{subModuleRender(sectionName)}</List>
        :
        <Fragment key={sectionName}>
          <Divider />
          <List subheader={
            <ListSubheader>
              <FlexStyled gap='5' align='center' >
                <Typo.ContentSmallHeader color={theme.colors.COURSIO}>:</Typo.ContentSmallHeader>
                <Typo.ContentSmallHeader>{t(`menu.${sectionName}`)}</Typo.ContentSmallHeader>
              </FlexStyled>
            </ListSubheader>
          }>
            {subModuleRender(sectionName)}
          </List>
        </Fragment>
    )
  };
  return (
    <div>
      <Drawer
        anchor={'left'}
        open={menuSliderToggle}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 300 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          {Profile}
          {roles && renderPanelItems(roles).map(item => sectionRender(item.section, item.withoutSectionName))}
          <Divider />
          <List>
            {(isAdmin || isIncarnated) &&
              <>
                {subSectionRender(handleTestPayment, 'ToggleOn', `Payments with: ${paymentType === 9 ? 'Klarna' : 'Stripe'}`)}
                {!isIncarnated && subSectionRender(openIncarnationModal, 'Fingerprint', t('globals.incarnate'))}
                {isIncarnated && subSectionRender(wakeUpFromIncarnation, 'Yoga', t('auth.wakeUp'))}
              </>
            }
          </List>
          <List>
            {isBasicUser && subSectionRender(handleSupportLink, 'Support', t('globals.support'))}
            {subSectionRender(handleLogOut, 'Logout', t('auth.logout'))}
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

export default MenuSlider;
