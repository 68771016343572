import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

import CourseContent from '../../../components/CourseContent';
import Teacher from '../../../components/Teacher';
import HighlightedInfo from '../../../components/HighlightedInfo';
import { Typo, Modal } from '../../../ui';
import Section from '../../../ui/Section';
import { CONTENT_MINI } from '../../../utils/media';
import { useSelector } from 'react-redux';
import { selectUserTheme } from '../globalSlice';
import { getLighterThemeColor } from '../../../ui/theme/colors';

const Wrapper = styled.div`
  padding: 2rem 1.5rem;
  p:first-child {
    margin-bottom: 50px;
  }
`

const CourseInPackageDetails = ({ onCloseModal, course }) => {
  const { t } = useTranslation();
  const userTheme = useSelector(selectUserTheme);
  const [visibleTabPanel, setVisibleTabPanel] = useState({ index: '0' })
  const [navigationItems, setNavigationItems] = useState([])

  const bgColor = useMemo(() => getLighterThemeColor(userTheme.color), [userTheme]);

  const closeModal = () => {
    onCloseModal()
  }

  const modalCourseLayout = course?.settings?.layout

  useEffect(() => {
    const tabList = []
    let index = 0
    if (modalCourseLayout?.highlightedInfo || modalCourseLayout?.introduction) {
      tabList.push({ label: t('course.packageAboutCourse'), value: 'learn', index: index })
      index++
    }
    if (modalCourseLayout?.courseContent) {
      tabList.push({ label: t('course.packageCourseContent'), value: 'content', index: index })
      index++
    }
    if (modalCourseLayout?.teacher && Object.keys(modalCourseLayout?.teacher).length) {
      tabList.push({ label: t('course.packageMeetTeacher'), value: 'teacher', index: index })
    }
    tabList.length && setVisibleTabPanel(tabList[0])
    setNavigationItems(tabList)
  }, [modalCourseLayout])


  const handleTabChange = (e, newValue) => {
    const selectedTab = navigationItems.find(element => element.index === newValue)
    setVisibleTabPanel(selectedTab)
  }

  return (
    <Modal
      width='800px'
      open={!!course}
      onClose={closeModal}
      compact
      title={course?.name}
      handleTabChange={handleTabChange}
      selectedTab={+visibleTabPanel.index}
      navigationItems={navigationItems}
    >
      <Wrapper
        hidden={visibleTabPanel.value !== 'learn'}
      >
        {!!modalCourseLayout?.introduction &&
          <Typo.Text $textAlign='center' >{modalCourseLayout.introduction?.text}</Typo.Text>
        }
        {!!modalCourseLayout?.highlightedInfo &&
          <HighlightedInfo 
            info={modalCourseLayout.highlightedInfo} 
            hasBottomMargin={false} 
            infoIcons={modalCourseLayout.infoIcons} 
            size='x-small' 
            courseId={modalCourseLayout.id} 
          />
        }
      </Wrapper>
      <div hidden={visibleTabPanel.value !== 'content'}>
        {!!modalCourseLayout?.courseContent &&
          <Section size={CONTENT_MINI} variant={bgColor}>
            <CourseContent tocData={modalCourseLayout.courseContent} />
          </Section>}
      </div>
      <div hidden={visibleTabPanel.value !== 'teacher'}>
        {!!modalCourseLayout?.teacher && <Teacher teacherInfo={modalCourseLayout.teacher} />}
      </div>
    </Modal >
  );
};

export default CourseInPackageDetails;
