import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { Typo, Flex, Icon } from '../../../ui';
import { getPeriodByTime } from '../../../utils/ucpPeriods';

const Wrapper = styled(Flex)`
  margin-left:-3px;
`

const ShowAccessTime = ({ ucpPeriod }) => {
  const { t } = useTranslation();

  const accessPeriod = t(`course.${getPeriodByTime(ucpPeriod)?.label}`)

  return (
    <Wrapper gap='9'  >
      <Icon.Calendar />
      <Typo.MediumText>{t('course.accessPeriodMessage', { ucp: accessPeriod })}</Typo.MediumText>
    </Wrapper>
  );
}

export default ShowAccessTime;

