import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SuggestedCourse from '../../../components/SuggestedCourse';
// import Testimonies from '../../../components/Testimonies';
import InfoIconSection from '../../../components/InfoIconSection';
import SpotlightedCourses from '../../../components/SpotlightedCourses';
import InfoPointsSection from '../../../components/InfoPointsSection';
import ListCourses from '../../../components/ListCourses';
import { Typo, Section, Flex } from '../../../ui'
import { selectLayout } from '../homeSlice';
import { selectCourseById, selectHighlightedCoursesInfo, selectWebshopCourses } from '../../webshop/coursesSlice';
import { addItemToCart, selectCartItemsIds } from '../cartSlice';
import { enqueueSnackbar } from 'notistack'
import { CONTENT_MINI } from '../../../utils/media';
import { TOAST_ERROR } from '../../../utils/constants';

const coursesRef = React.createRef();
const aboutRef = React.createRef();
const contentRef = React.createRef();

const ViewHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const layoutStructure = useSelector(selectLayout);
  const { t } = useTranslation();

  const {
    introduction,
    infoPoints,
    infoIcons,
    suggestedCourse,
    highlightedCourses,
    // testimonies,
  } = layoutStructure;

  const courses = useSelector(selectWebshopCourses);
  const suggestedCourseInfo = useSelector(state => selectCourseById(state, suggestedCourse));//getCourseInfo(courses, suggestedCourse)
  const spotlightedCoursesList = useSelector(state => selectHighlightedCoursesInfo(state, highlightedCourses));//highlightedCourses && getHighlightedCoursesInfo(courses, highlightedCourses)
  const cartItems = useSelector(selectCartItemsIds);

  const scrollTo = useCallback((refTarget) => {
    let headerOffset = 250;

    if (!refTarget.current) return null;
    let elementPosition = refTarget?.current.getBoundingClientRect().top;
    let offsetPosition = elementPosition - headerOffset;

    window.scrollBy({
      top: offsetPosition,
      behavior: "smooth"
    });
    // clean hash
    let noHashURL = window.location.href.replace(/#.*$/, '');
    window.history.replaceState('', document.title, noHashURL);
  }, []);

  useEffect(() => {
    if (hash) {
      if (hash === '#courses') scrollTo(coursesRef);
      else if (hash === '#content') scrollTo(contentRef);
      else if (hash === '#about') scrollTo(aboutRef);
    }
  }, [hash, scrollTo]);

  const addItem = useCallback((item) => {
    if (cartItems.find(el => el === item.id)) enqueueSnackbar(t('webshop.alreadyInCart'), { variant: TOAST_ERROR })
    else if (!!cartItems.length) {
      enqueueSnackbar(t('webshop.canOnlyBuyOne'), { variant: TOAST_ERROR })
    } else {
      dispatch(addItemToCart(item));
      navigate('checkout')
    }
  }, [dispatch, cartItems, t]);

  if (!layoutStructure) return null

  const hasLayoutToShow = !!suggestedCourseInfo || !!spotlightedCoursesList.length

  return (
    <>
      {!!infoIcons.length && <Section noMarginBottom={true} >
        <InfoIconSection items={infoIcons}/>
      </Section>}
      {!!Object.keys(introduction).length &&
        <Section size={CONTENT_MINI} >
          <Flex gap='24' direction='column'>
            <Typo.PageHeader>{introduction?.title}</Typo.PageHeader>
            <Typo.Text ref={aboutRef} >{introduction?.text}</Typo.Text>
          </Flex>
        </Section>
      }
      {!!spotlightedCoursesList.length &&
        <SpotlightedCourses courses={spotlightedCoursesList} />
      }

      <InfoPointsSection items={infoPoints} title={t('webshop.home.infoPointsTitle')} refContent={contentRef} />

      {!!suggestedCourseInfo &&
        <SuggestedCourse
          suggestedCourse={suggestedCourseInfo}
          addToCart={addItem}
        />
      }

      {/*{!!testimonies?.length && <Testimonies items={testimonies} />}*/}
      {!!courses.length && (courses.length > 1 || !(hasLayoutToShow)) ?
        <Section title={t('webshop.home.coursesTitle')}>
          <ListCourses
            showProgress={false}
            showButtons={true}
            items={courses}
            addItem={addItem}
            refCourses={coursesRef} />
        </Section>
        : null
      }
    </>
  );
};

export default ViewHome;
