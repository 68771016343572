import React, {useCallback, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { FormControlLabel, Checkbox } from '@mui/material';
import {
  selectPrivacyAcceptance,
  setPrivacyAcceptance,
  selectPaymentEmail,
  selectIsAGift,
  selectPaymentStatus,
  setPaymentEmail,
  startPaymentProcess,
  startGuestPaymentProcess,
} from '../../paymentsSlice';
import { Typo, Button, Flex } from '../../../../ui';
import { selectAuthState, selectUser } from '../../../auth/authSlice';
import { selectCountry, selectDiscountCode, selectPaymentType, selectStoreName, setPaymentType } from '../../globalSlice';
import { STATUS_LOADING, currenciesByCountry } from '../../../../utils/constants';


const AcceptTermsGoToPayment = ({ cartItems }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const privacyAcceptance = useSelector(selectPrivacyAcceptance);
  const paymentEmail = useSelector(selectPaymentEmail);

  const authState = useSelector(selectAuthState);
  const storeName = useSelector(selectStoreName);
  const isAGift = useSelector(selectIsAGift);
  const user = useSelector(selectUser);
  const paymentStatus = useSelector(selectPaymentStatus);
  const paymentType = useSelector(selectPaymentType);
  const discountCode = useSelector(selectDiscountCode);
  const country = useSelector(selectCountry);

  const handleChange = (event) => {
    dispatch(setPrivacyAcceptance(event.target.checked));
  };

  useEffect(() => {
    if (user) {
      dispatch(setPaymentEmail(user.email))
      dispatch(setPrivacyAcceptance(true))
    }
  }, [user, dispatch])

  useEffect(() => {
    if (cartItems[0].currency !== currenciesByCountry[country]) {
      dispatch(setPaymentType(6));
    }
  }, [cartItems])


  const startPaymentSession = useCallback(() => {
    const gifterEmail = !!isAGift ? paymentEmail : null;

    if (paymentStatus !== STATUS_LOADING) {
      if (authState.loggedIn) {
        dispatch(startPaymentProcess({ providerId: paymentType, storeName, cart: cartItems, gifterEmail, discountCode, country }))
      } else {
        dispatch(startGuestPaymentProcess({ providerId: paymentType, storeName, cart: cartItems, gifterEmail, targetEmail: paymentEmail, discountCode, country }))
      }
    }

  }, [authState, cartItems, dispatch, paymentEmail, storeName, isAGift, paymentStatus, paymentType]);

  return (
    <Flex width='100%' justify={authState.loggedIn ? 'flex-end' : 'space-between'}>
     {!authState.loggedIn && <FormControlLabel
        control={<Checkbox checked={privacyAcceptance} onChange={handleChange} name="checkedA" />}
        label={<Typo.Info>{t('webshop.checkout.agreeWith')} <a href="https://coursio.com/gdpr" target="_blank" rel="noopener noreferrer">{t('webshop.checkout.privacyPolice')}</a></Typo.Info>}
      />}
      <Button width='100%' $variant='action'  disabled={!privacyAcceptance || !paymentEmail} onClick={startPaymentSession}>{t('webshop.checkout.goToPayment')}</Button>
    </Flex>
  );
};

export default AcceptTermsGoToPayment;

