import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { storeApi, courseApi, coursePackageApi } from '../../utils/urls';
import { nanoid } from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { enqueueSnackbar } from 'notistack'
import httpClient from '../../services/httpClient';
import { viewItemGa } from '../../helpers/GATracker';

// ----------------- Thunks -----------------------------

//WHEN SAVING LAYOUT:  we need to make sure we dont delete any other data that it is inside settings

export const fetchCourse = createAsyncThunk('course/fetchCourse', async ({ storeName, slugName, type }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      discountCode: null,
      slug: slugName,
      type,
      toc: 1,
      username: storeName
    },
    method: 'findBySlug'
  };

  const res = await httpClient.post(storeApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

export const toggleCourseVisibility = createAsyncThunk('course/toggleVisibility', async ({ courseId, isPackage }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      id: courseId
    },
    method: 'toggleVisibility'
  };

  const url = !isPackage ? courseApi() : coursePackageApi();

  const res = await httpClient.post(url, body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'toggle course visibility'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const createCoursePackage = createAsyncThunk('course/createCoursePackage', async ({ newData }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: newData,
    method: 'create'
  };

  const res = await httpClient.post(coursePackageApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Course package created with success'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data
})

export const saveCourse = createAsyncThunk('course/saveCourse', async ({ newData, isPackage }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: newData,
    method: 'update'
  };
  const url = !isPackage ? courseApi() : coursePackageApi();
  const res = await httpClient.post(url, body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Course settings saved with success'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data
})

export const deleteCoursePackage = createAsyncThunk('course/deleteCoursePackage', async (id, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { id },
    method: 'delete'
  };

  const res = await httpClient.post(coursePackageApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Course package deleted with success'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

// ----------------- Reducers ---------------------------

const mockId = (data) => data.map(el => { return { ...el, id: nanoid() } });

const initialState = {
  id: null,
  status: 'idle', // or: 'loading', 'succeeded', 'failed'

  teacher: {}, //name:'', about:'', avatarUrl:''},
  highlightedInfo: {}, //title:'', text:'', image:{id:'', url:''}},
  coverMedia: { video: { id: null, url: null }, image: { id: null, url: null } },//video:{id:'', url:''}, image:{id:'', url:''}}, //image is not editable only when creating course
  ctaBanner:{}, //title:'', descrition:''
  introduction: {},//title: '' }, Title is not editable only when creating course
  infoIcons: [],//{iconName:'', title:'', text:'', id:''}],
  infoPoints: [],//{title:'', text:'', id:''}],
  courseContent: {}, //do not present raw toc but allow to edit the toc to set proper description
  courseToc: {},

  price: {},//{gross:'', net:'', discounted:''}
  ucpPeriod: 2592000,
  currency: 'SEK',
  isPublic: 0,

  dontDeleteSettings: {},

  testimonies: [],//{image:'', name:'', text:''}]
  cta: ''
  // emailGatherer:'',

}

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(toggleCourseVisibility.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(toggleCourseVisibility.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(fetchCourse.pending, (state, action) => {
        return { ...initialState, status: STATUS_LOADING };
        // state.status = STATUS_LOADING;
      })
      .addCase(fetchCourse.rejected, (state, action) => {
        state.status = STATUS_FAILED
      })
      .addCase(fetchCourse.fulfilled, (state, action) => {
        if (!action.payload) {
          state.status = STATUS_FAILED

        } else {
          state.status = STATUS_SUCCEEDED

          state.id = action.payload.id

          //GA
          viewItemGa({
            id: action.payload.id,
            name: action.payload.name,
            price: action.payload.price.discounted,
            quantity: 1
          })

          //NOT EDITABLE INFORMATION IN NEW LAYOUT
          //If course is a bundle, coverImage can have more than one object.
          if (action.payload.coverImage[0]) state.coverMedia = { image: action.payload.coverImage[0] }
          if (action.payload.currency) state.currency = action.payload.currency
          if (action.payload.name) state.introduction = { ...state.introduction, title: action.payload.name }
          const isBundle = action.payload.type === 2

          if (!isBundle && action.payload.courses) {
            state.courseToc = action.payload.courses[0].toc
          }

          // If it is a bundle we will have several courses:
          const parseSettings = (settings, courseToc) => {
            const info = {}
            const { teacher, highlightedInfo,
              infoIcons, infoPoints, testimonies, introduction, ctaBanner } = settings.layout

            info.teacher = teacher;
            info.highlightedInfo = highlightedInfo;
            info.infoPoints = infoPoints;
            info.infoIcons = mockId(infoIcons);
            info.infoPoints = mockId(infoPoints);
            info.testimonies = testimonies;
            info.introduction = introduction;

            return info
          }

          if (isBundle) {
            state.packageItems = action.payload.courses.map((item) => {
              let newCourseLayout = {}
              const settingsData = item.config;
              if (settingsData && Object.keys(settingsData).length) {
                if (settingsData.layout && Object.keys(settingsData.layout).length)
                  newCourseLayout = parseSettings(settingsData, item.toc)
                else newCourseLayout = null
              }
              if (item.toc) {
                const toc = settingsData?.layout?.courseContent || item.toc
                newCourseLayout = {...newCourseLayout, courseContent: toc}
              }
              return {...item, settings: {...settingsData, layout: newCourseLayout}}
            })
          }


          // EDITABLE INFORMATION IN NEW LAYOUT
          if (action.payload.videoUrl) state.coverMedia = { ...state.coverMedia, video: { url: action.payload.videoUrl } }
          if (action.payload.price) state.price = action.payload.price
          if (action.payload.ucpPeriod) state.ucpPeriod = action.payload.ucpPeriod
          if (action.payload.public) state.isPublic = action.payload.public

          if (!action.payload?.courses[0]?.config?.layout) return
          // if (!action.payload?.settings?.length) return

          // const settingsData = JSON.parse(action.payload.settings);

          // if (Object.keys(settingsData).length) {
          //   state.dontDeleteSettings = { ...settingsData }
          //
          //   if (settingsData.layout && Object.keys(settingsData.layout).length) {
          //     delete state.dontDeleteSettings.layout;
          const { teacher, highlightedInfo,
            infoIcons, infoPoints, courseContent, testimonies, courseCoverMedia, ctaBanner } = action.payload.courses[0].config.layout;

          // VARIABLES UNIQUE TO NEW LAYOUT
          state.coverMedia = Array.isArray(courseCoverMedia) ? { video: {}, image: {} } : courseCoverMedia;
          state.teacher = teacher;
          state.highlightedInfo = highlightedInfo;
          state.infoPoints = infoPoints;
          state.infoIcons = mockId(infoIcons);
          state.infoPoints = mockId(infoPoints);
          state.courseContent = courseContent || state.courseToc;
          state.testimonies = testimonies;
          // state.cta = cta;
          state.ctaBanner = ctaBanner;

          // if (settingsData.layout && Object.keys(settingsData.layout).length) {
          //   delete state.dontDeleteSettings.layout;
          //   const { teacher, highlightedInfo,
          //     infoIcons, infoPoints, courseContent, testimonies, courseCoverMedia, ctaBanner } = settingsData.layout

          //   // VARIABLES UNIQUE TO NEW LAYOUT
          //   state.coverMedia = Array.isArray(courseCoverMedia) ? { video: {}, image: {} } : courseCoverMedia;
          //   state.teacher = teacher;
          //   state.highlightedInfo = highlightedInfo;
          //   state.infoPoints = infoPoints;
          //   state.infoIcons = mockId(infoIcons);
          //   state.infoPoints = mockId(infoPoints);
          //   state.courseContent = courseContent || state.courseToc;
          //   state.testimonies = testimonies;
          //   state.ctaBanner = ctaBanner;
          // }
          // }
        }
        // }
        // }
      })
  }
})

export default courseSlice.reducer

// ----------------- Selectors --------------------------
export const selectCourseCoverMedia = state => state.webshop.course.coverMedia;
export const selectCtaBanner = state => state.webshop.course.ctaBanner
export const selectLayout = state => state.webshop.course;
export const selectCourseIsPublic = state => state.webshop.course.isPublic;
export const selectLayoutId = state => state.webshop.course.id;
export const selectStatus = state => state.webshop.course.status;

