import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Typo } from '../../../ui';
import { TOAST_ERROR } from '../../../utils/constants';
import { addItemToCart, selectCartItemsIds } from '../cartSlice';
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next';
import { priceFormater } from '../../../helpers/formatNumbers';
import { selectCurrentStoreHideVat, selectStoreName } from '../globalSlice';
import { useNavigate } from 'react-router';
import { selectCourseById } from '../coursesSlice';

const Wrapper = styled(Flex)`
  margin: ${({ $noMargin }) => $noMargin ? '0 auto': '2.5rem auto 0' }; ;
`;

const CtaButton = ({ courseId, compact, inverted, $themeColor, $bgColor }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeName = useSelector(selectStoreName);
  const course = useSelector(state => selectCourseById(state, courseId))
  const cartItems = useSelector(selectCartItemsIds);
  const hideVAT = useSelector(selectCurrentStoreHideVat)

  const addItem = useCallback(() => {
    if (cartItems.find(el => el === course.id)) enqueueSnackbar(t('webshop.alreadyInCart'), { variant: TOAST_ERROR })
    else if (!!cartItems.length) {
      enqueueSnackbar(t('webshop.canOnlyBuyOne'), { variant: TOAST_ERROR })
    } else {
      dispatch(addItemToCart(course))
      navigate(`/store/${storeName}/checkout`);
    }
  }, [dispatch, cartItems, t, course]);

  return (+course?.price?.gross > 0 ?
    inverted ? 
    <Flex gap={15} width='100%' maxWidth={'38%'} justify='flex-end'>
      <Flex align='flex-end' gap='5' direction='column'>
        <Typo.TextTitle $textAlign='right' $themeColor={$bgColor}>{priceFormater(course?.price, hideVAT, course?.currency)}</Typo.TextTitle>
        {hideVAT && <Typo.Info $themeColor={$bgColor}>{t('globals.priceExcVat')}</Typo.Info>}
      </Flex>
      <Button $themeColor={$themeColor} $variant='themed' onClick={addItem} >{t('webshop.buy')}</Button>
    </Flex> : 
    <Wrapper align='center' direction='column' $noMargin={compact}>
      <Flex align='baseline' gap='5'>
        <Typo.TextTitle>{priceFormater(course?.price, hideVAT, course?.currency)}</Typo.TextTitle>
        {hideVAT && <Typo.Info>{t('globals.priceExcVat')}</Typo.Info>}
      </Flex>
      <Button
        onClick={addItem}
        $variant='action'
      >
        {t('webshop.buy')}
      </Button>
    </Wrapper> : <></>
  );
};

export default CtaButton;
